@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER (USING *BOOTSTRAP* STANDARD BREAKPOINT)
/*
Using max-width Media Queries except xxl device
0 - 576px       ==> xs =>  X-Small devices (portrait phones, less than 576px)
576px - 768px   ==> sm =>  Small devices (landscape phones, less than 768px)
768px - 992px   ==> md =>  Medium devices (tablets, less than 992px)
992px - 1200px    ==> lg =>  Large devices (desktops, less than 1200px)
1200 - 1400px   ==> xl =>  X-Large devices (large desktops, less than 1400px)
1400 - ****px   ==> xxl=>   XX-Large devices (larger desktops) => Using min-width media query since the xxl breakpoint has no upper bound on its width

$breakpoint arguement choices:
Using max-width Media Queries except xxl device
xs =>  X-Small devices (portrait phones, less than 576px)
sm =>  Small devices (landscape phones, less than 768px)
md =>  Medium devices (tablets, less than 992px)
lg =>  Large devices (desktops, less than 1200px)
xl =>  X-Large devices (large desktops, less than 1400px)
xxl=>   XX-Large devices (larger desktops) => Using min-width media query since the xxl breakpoint has no upper bound on its width


ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

@mixin mediaDesktopFirst($breakpoint) {
    // Desktop First Media Queries
    @if $breakpoint == lg {
        // 75em = 1200px
        @media only screen and (max-width: 75em) {
            @content;
        }
    }
    @if $breakpoint == md {
        // 62em = 992px
        @media only screen and (max-width: 62em) {
            @content;
        }
    }
    @if $breakpoint == sm {
        // 48em = 768px
        @media only screen and (max-width: 48em) {
            @content;
        }
    }
    @if $breakpoint == xs {
        // 36em = 576px
        @media only screen and (max-width: 36em) {
            @content;
        }
    }
    @if $breakpoint == xxl {
        // 87.5em = 1400px ==> using min-width
        @media only screen and (min-width: 87.5em) {
            @content;
        }
    }
}
