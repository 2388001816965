.sideNavbar {
    &__container {
        width: 0%;
    }

    &__dropdownSelect {
        margin-left: 70px;
        border: none;
        outline: none;
        background-color: #fff;
        scroll-behavior: smooth;
    }

    &__dropdownOption {
        // border: none;
        // outline: none;
        // scroll-behavior: smooth;
    }
}
