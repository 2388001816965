.header {
    &__container {
        z-index: 99;
    }

    &__btn {
        cursor: pointer;
    }
    &__measureLogo {
        // background-color: red;
    }
}
