.bottom {
    &__main {
        position: fixed;
        z-index: 999;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__container {
        box-shadow: 0 0 0 2px rgba(47, 92, 133, 0.4);
    }

    &__fontSize {
        font-size: 13px !important;
    }

    &__offlineMessage {
        width: 100%;
        background-color: #f44444;
        color: white;
        text-align: center;
    }

    &__onlineMessage {
        width: 100%;
        background-color: green;
        color: white;
        text-align: center;
    }
}
