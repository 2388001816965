body {
    font-family: "Poppins";
    font-weight: 400;
    /*font-size: 16px;*/
    line-height: 1.7;
    color: $color-grey-dark;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;

    backface-visibility: hidden;
    margin-bottom: 3.75rem;

    &--main {
        display: block;
        font-size: 3.75rem;
        font-weight: 400;
        letter-spacing: 2.19rem;

        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;

        @include mediaDesktopFirst(xs) {
            letter-spacing: 0.625rem;
            font-family: 3.12rem;
        }
        /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
    }

    &--sub {
        display: block;
        font-size: 1.252rem;
        font-weight: 700;
        letter-spacing: 1.1rem;
        animation: moveInRight 1s ease-out;

        @include mediaDesktopFirst(xs) {
            letter-spacing: 0.3125rem;
        }
    }
}

.heading-secondary {
    font-size: 2.1875rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 0.125rem;
    transition: all 0.2s;

    @include mediaDesktopFirst(md) {
        font-size: 1.78rem;
    }

    @include mediaDesktopFirst(xs) {
        font-size: 1.5625rem;
    }

    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: 0.3125rem 0.625rem 1.25rem rgba($color-black, 0.2);
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;

    &:not(:last-child) {
        margin-bottom: 1.875rem;
    }
}
