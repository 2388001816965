.roomlist {
    margin-top: 70px !important;
    margin-bottom: 70px !important;

    &__heading {
        margin-left: -23px;
        text-align: center;
    }
    &__container {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include mediaDesktopFirst(lg) {
            grid-template-columns: 1fr 1fr;
        }

        @include mediaDesktopFirst(md) {
            grid-template-columns: 1fr 1fr;
        }

        @include mediaDesktopFirst(sm) {
            grid-template-columns: 1fr;
        }

        @include mediaDesktopFirst(xs) {
            grid-template-columns: 1fr;
        }
    }

    &__item {
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
    }
}
