.room {
    margin-top: 70px;
    margin-bottom: 70px;

    &__heading {
        margin-left: -23px;
        text-align: center;
    }

    &__temperatureContainer {
        width: 95%;
        padding: 0px;
        display: grid;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr 1fr;
        margin-left: 2%;
    }
    &__temperatureCard {
        text-align: center;
    }

    &__block {
        padding: 10px;
        margin: 5px;
        height: 100px;
        align-content: center;
    }

    &__block_small {
        padding: 10px;
        margin: 5px;
        height: 55px;
        min-width: 85px;
        align-content: center;
    }

    &__temperatureTitle {
        font-size: 15px;
        transform: translate(50%, 50%);
        @include mediaDesktopFirst(sm) {
            font-size: 12px;
        }
    }

    &__temperature {
        color: #fff;
        font-size: 25px;
        @include mediaDesktopFirst(sm) {
            font-size: 20px;
        }
    }

    &__btnTemp {
        margin: 40px 10px 10px 10px;
        margin-top: 30px;
    }

    &__table {
        width: 100% !important;
        background-color: #fff;
    }

    &__heating {
        color: green;
    }
    &__heatingProblem {
        color: red;
    }

    &__checkbox {
        margin-top: 9px;
    }

    &__deviceBtn {
        width: 20px;
    }

    &__roomLog {
        margin-left: 30px;
        background: #fff;
        padding: 10px;
        width: 96%;
        border-radius: 5px;
        line-height: 2.2;
        @media only screen and (min-width: 112.5em) {
            width: 93%;
        }
        @media only screen and (max-width: 75em) {
            width: 95%;
        }
        @media only screen and (max-width: 56.25em) {
            width: 94%;
        }
        @media only screen and (max-width: 37.5em) {
            width: 90%;
            margin-left: 20px;
        }
    }
}

.transactions .item {
    padding: 5px;
    .text-align-center {
        text-align: center;
    }
}

.sliderContainer {
    // slick_carousel
    .slick-track {
        margin-left: 30px;
    }
    .slick-next {
        right: 0 !important;
    }
    .slick-prev {
        left: -16px !important;
    }
}
