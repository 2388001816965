.splash {
    &__progressLoading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__container {
        width: 100vw;
        height: 100vh;
    }
    &__logo {
        margin-top: 20px;
        width: 150px;
        margin: 0.625rem;
    }

    &__img {
        width: 95%;
        height: 300px;
        margin-left: 2% !important;
    }

    &__login {
        margin-left: 10%;
        margin-top: 80px;
    }
}
