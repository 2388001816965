.dashboard {
  &__container {
    display: grid;
    margin-top: 70px;
    grid-template-columns: 1fr 1fr 1fr;

    @include mediaDesktopFirst(lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include mediaDesktopFirst(md) {
      grid-template-columns: 1fr 1fr;
    }

    @include mediaDesktopFirst(sm) {
      grid-template-columns: 1fr;
    }

    @include mediaDesktopFirst(xs) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    margin: 5px 10px 5px 10px;
    cursor: pointer;
  }
}
