.bg-primary{
    background: $colorPrimary !important;
    color: #FFF;
}
.bg-secondary{
    background: $colorSecondary !important;
    color: #FFF;
}
.bg-tertiary{
    background: $colorTertiary !important;
    color: #000 !important;
}
.bg-success{
    background: $colorSuccess !important;
    color: #FFF;
}
.bg-danger{
    background: $colorDanger !important;
    color: #FFF;
}
.bg-warning{
    background: $colorWarning !important;
    color: #FFF;
}
.bg-info{
    background: $colorInfo !important;
    color: #FFF;
}
.bg-light{
    opacity: 0.3;
}
.bg-dark{
    background: #222 !important;
    color: #FFF;
}

.text-primary,
a.text-primary{
    color: $colorPrimary !important;
}
.text-secondary,
a.text-secondary{
    color: $colorSecondary !important;
}
.text-success,
a.text-success{
    color: $colorSuccess !important;
}
.text-danger,
a.text-danger{
    color: $colorDanger !important;
}
.text-warning,
a.text-warning{
    color: $colorWarning !important;
}
.text-info,
a.text-info{
    color: $colorInfo !important;
}
.text-light,
a.text-light{
    color: #FFF !important;
}
.text-dark,
a.text-dark{
    color: $colorHeading !important;
}